<template>
	<v-content>
		<v-container fluid class="mt-12">
			<v-row justify="center">
				<v-col cols="12" sm="8" md="4">
					<v-card class="elevation-0">
						<div class="title title-container mb-10">
							<h1 class="text-center">SSO Connexion</h1>
						</div>
					</v-card>
				</v-col>
			</v-row>
			<v-row justify="center">
				<v-col cols="12" sm="8" md="4" class="text-center">
					<v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular
				></v-col>
			</v-row>
		</v-container>
	</v-content>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: 'LoginSSOProcess',
	data: () => ({
		loading: true,
	}),
	computed: {
		...mapGetters('accountManager', { formation: 'formation' }),
	},
	async created() {
		try {
			const urlParams = new URLSearchParams(window.location.search);
			const token = urlParams.get('token');
			const userId = urlParams.get('userId');
			if (!token || !userId) return this.$router.push({ name: 'Login' });
			await this.$store.dispatch('accountManager/setToken', { token });
			await this.$store.dispatch('accountManager/getUser', { idUser: userId });
			if (this.formation.onboarding_done == true) this.$router.push({ name: 'Dashboard' });
			else this.$router.push(`/onboarding/${userId}`);
		} catch (error) {
			console.error('Oops! Something went wrong:::', error);
			this.$router.push({ name: 'Logout' });
		}
	},
};
</script>
